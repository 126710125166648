<template>
  <span @click="copy(copyInfo)">{{ text }}</span>
</template>

<script>
import {Toast} from "vant";
export default {
  name: 'copy',
  props: {
    text: {
      type: String,
      default: '复制'
    },
    copyInfo: {
      type: String,
      default: ''
    }
  },
  methods: {
    copy(content) {
      let copyDom = document.createElement('div');
      copyDom.innerText = content;
      copyDom.style.position = 'absolute';
      copyDom.style.top = '0px';
      copyDom.style.right = '-9999px';
      document.body.appendChild(copyDom);
      //创建选中范围
      let range = document.createRange();
      range.selectNode(copyDom);
      //移除剪切板中内容
      window.getSelection().removeAllRanges();
      //添加新的内容到剪切板
      window.getSelection().addRange(range);
      //复制
      let successful = document.execCommand('copy');
      copyDom.parentNode.removeChild(copyDom);
      try {
        let msg = successful ? "successful" : "failed";
        Toast.success('复制成功')
        console.log('Copy command was : ' + msg);
      } catch (err) {
        console.log('Oops , unable to copy!');
      }
    }
  }
}
</script>

<style>
</style>
