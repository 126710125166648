<template>
	<div class="border" :style="{'height':height,'background-color':bgColor}">
		
	</div>
</template>

<script>
	export default{
		props:{
			height:{
				type:String,
				default:'1px'
			},
			bgColor:{
				type:String,
				default:'#F4F4F4'
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
