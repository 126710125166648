<template>
	<div class="express-line">
		<div v-for="(item,index) in list" class="flex" :class="{'first':index == 0}" :key="index">
			<div class="time">
				<div class="time-year">
					{{item.scanTime.substring(5,10)}}
				</div>
				<div class="time-hour">
					{{item.scanTime.substring(11,16)}}
				</div>
			</div>
			<div class="point"></div>
			<div class="flex_item">
				<div class="state">
					{{item.status}}
				</div>
				<div class="note">
					{{item.context}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			list:{
				type:Array,
				default:()=>{}
			}
		}
	}
</script>

<style lang="less" scoped>
	.express-line{
		position: relative;
		
		&::after{
			content: '';
			position: absolute;
			left: 45.5px;
			top: 11px;
			bottom:31px;
			border-left: 1px dashed #979797;
		}
		.time{
			text-align: center;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			.time-year{
				font-size: 11px;
				color: #666;
				line-height: 15px;
			}
			.time-hour{
				font-size: 8px;
				color: #666666;
				line-height: 12.5px;
			}
		}
		.point{
			width: 5px;
			height: 5px;
			background: #999999;
			border-radius: 100%;
			margin: 6px 11px;
		}
		.state{
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 15px;
		}
		.note{
			font-size: 11px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 15px;
			margin-bottom: 10px;
		}
		.first{
			.point{
				background: #DC2727;
			}
			.time-year{
				color: #333333;
			}
			.state{
				color: #333333;
			}
			.note{
				color: #333333;
			}
		}
	}
</style>
