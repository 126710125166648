import { render, staticRenderFns } from "./no-data.vue?vue&type=template&id=97dca150&scoped=true&"
import script from "./no-data.vue?vue&type=script&lang=js&"
export * from "./no-data.vue?vue&type=script&lang=js&"
import style0 from "./no-data.vue?vue&type=style&index=0&id=97dca150&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97dca150",
  null
  
)

export default component.exports