<template>
  <div class="list">
    <div class="coupon_list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" offset="50" @load="onLoad"
          :immediate-check=false>
          <div class="coupon_item" v-for="(val, key) in couponsData" :key="key">
            <div class="coupon_left">
              <div class="money">
                <span v-if="val.types == 1">¥{{ val.discount }}</span>
                <span v-if="val.types == 2">{{ val.discount }}折</span>
              </div>
              <div class="full_money">
                满{{ val.condition }}元可用
              </div>
              <div class="vouchers">
                <span v-if="val.types == 1">满减券</span>
                <span v-if="val.types == 2">折扣券</span>
                <span v-if="val.types == 3">商品兑换券</span>
              </div>
            </div>
            <div class="coupon_right">
              <div class="coupon_name_box">
                <div class="coupon_right_name">{{ val.title }}</div>
              </div>
              <div class="validity">
                有效期：{{ val.effectiveStartTime }} ~ {{ val.effectiveEndTime }}
              </div>
            </div>
          </div>
          <NoData v-if="couponsData && couponsData.length == 0"></NoData>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getCoupon } from '@/api/UdeskCustomer'
import { Toast } from 'vant';
import NoData from '@/components/no-data/no-data.vue';

export default {
  name: "coupon",
  components: {
    NoData
  },
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      couponsData: null,
      pageNum: 1,
      loading: false,
      refreshing: false,
      finished: false
    }
  },
  mounted() {
    this.loadCoupon()
  },
  methods: {
    loadCoupon() {
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      this.loading = true
      let pageNum = this.pageNum
      const params = {
        pageNum,
        pageSize: 10,
        userId: this.$props.userId
      }
      if (this.couponsData && this.couponsData.length == this.total) {
        this.refreshing = false
        this.finished = true
        Toast.clear()
        return
      }
      getCoupon(params).then(res => {
        res.data.records.forEach(item => {
          item.effectiveEndTime = item.effectiveEndTime.slice(0, 10)
          item.effectiveStartTime = item.effectiveStartTime.slice(0, 10)
        })
        if (pageNum == 1) {
          this.couponsData = res.data.records
        } else {
          this.couponsData = this.couponsData.concat(res.data.records)
        }
        this.total = res.data.total
        this.loading = false
        this.refreshing = false
        Toast.clear()
      })
    },
    onLoad() {
      this.pageNum++
      this.loadCoupon()
    },
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.couponsData = []
      this.loadCoupon()
    }
  }
}
</script>

<style scoped lang="less">
.list {
  .coupon_list {
    padding: 10px 15px;
    box-sizing: border-box;

    .coupon_item {
      background: url(https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/cloudPlatfrom/images/coupon_bg.png) no-repeat;
      background-size: 100%;
      height: 83px;
      margin-bottom: 10px;
      display: flex;

      .coupon_left {
        text-align: center;
        font-size: 8px;
        font-weight: 400;
        color: #FCDBDC;
        width: 28.2%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        line-height: 12.5px;

        .money {
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 4px;
          margin-top: 15px;
          line-height: 21px;
        }

        .full_money {
          margin-bottom: 12px;
        }
      }

      .coupon_right {
        width: 71.8%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 7.5px 10px 6.5px 8.5px;
        box-sizing: border-box;

        .coupon_name_box {
          display: flex;
          align-items: center;
          font-weight: 400;

          .coupon_right_name {
            flex: 1;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            height: 21px;
            font-size: 14px;
            color: #333333;
            line-height: 42rpx;
          }

          .more_rules {
            height: 14px;
            font-size: 9px;
            color: #999999;
            line-height: 14px;
            margin-left: 21px;
          }
        }

        .validity {
          height: 14px;
          font-size: 9px;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
          margin-top: 3px;
        }

        .btn_box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 10px;

          .btn {
            padding: 4px 15px;
            border-radius: 11px;
          }

          .promote {
            border: 1px solid #DC2727;
            font-size: 10px;
            font-weight: 400;
            color: #DC2727;
            line-height: 14px;
            margin-right: 25px;
          }

          .share {
            background: linear-gradient(270deg, #F04F4F 0%, #DC2727 100%);
            font-size: 10px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>
