<template>
  <div class="orderHistory">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" offset="50" @load="onLoad"
        :immediate-check=false>
        <div class="order-history-item" v-for="(item, i) in orderHistoryList" :key="i">
          <div class="top">
            <div class="time">{{ item.payTime }}</div>
            <div>{{ item.orderStatus }}</div>
          </div>
          <div class="commodity" v-for="(val, i) in item.goods" :key="i">
            <img class="commodity-img" :src="val.goodsImage" />
            <div class="commodity-content">
              <div class="commodity-title">{{ val.goodsTitle }}</div>
              <div class="commodity-buy-num">{{ val.goodsAttr }}</div>
            </div>
            <div class="prices">
              <div class="price">¥{{ val.realPrice }}</div>
              <div class="num">x{{ val.buyNum }}</div>
            </div>
          </div>
          <div class="count-box">
            <span class="count">共{{ item.count }}件 实付:¥
              <span class="money">{{ item.money }}</span>
            </span>
            <span class="btn logistics" @click="toExpress(item.orderId)">查看物流</span>
            <span class="btn remark" @click="toRemarks(item.orderId)">备注</span>
          </div>
          <div class="order-no" @click="toDetail(item.orderId)">
            <span>订单编号: {{ item.parentOrderNo }}</span>
            <van-icon name="arrow" class="icon" />
          </div>
        </div>
        <NoData v-if="orderHistoryList && orderHistoryList.length == 0"></NoData>
      </van-list>
    </van-pull-refresh>
    <remarks v-if="showRemarks" :orderId="orderId" :phone="phone" @hideRemarks="hideRemarks"></remarks>
    <express v-if="showExpress" :orderId="orderId" @hideExpress="hideExpress"></express>
  </div>
</template>

<script>
import { historyOrders } from '@/api/UdeskCustomer'
import remarks from './remarks.vue'
import express from './express.vue'
import { Toast } from 'vant';
import NoData from '@/components/no-data/no-data.vue';
export default {
  name: "orderHistory",
  components: {
    remarks,
    express,
    NoData
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      orderHistoryList: null,
      showRemarks: false,
      showExpress: false,
      pageNum: 1,
      total: null,
      orderId: null,
      loading: false,
      refreshing: false,
      finished: false
    }
  },
  computed: {
  },
  mounted() {
    this.loadHistoryOrders()
  },
  methods: {
    toExpress(orderId) {
      this.orderId = orderId
      this.showExpress = true
    },
    hideExpress() {
      this.showExpress = false
    },
    toRemarks(orderId) {
      this.orderId = orderId
      this.showRemarks = true
    },
    toDetail(orderId) {
      window.open(`${process.env.VUE_APP_MANAGE_55}/order/detail?orderid=${orderId}&settlementtype=1&usertypes=1&platform=0`)
    },
    hideRemarks() {
      this.showRemarks = false
    },
    loadHistoryOrders() {
      this.loading = true
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      let pageNum = this.pageNum
      const params = {
        pageNum,
        pageSize: 10,
        userId: this.$props.userId
      }
      if (this.orderHistoryList && this.orderHistoryList.length == this.total) {
        this.refreshing = false
        this.finished = true
        Toast.clear()
        return
      }
      historyOrders(params).then(res => {
        res.data.records.forEach((item) => {
          item.count = 0
          item.money = 0
          item.goods.forEach((goods) => {
            item.count += goods.buyNum
            item.money += goods.actPrice
          })
        })
        if (pageNum == 1) {
          this.orderHistoryList = res.data.records
        } else {
          this.orderHistoryList = this.orderHistoryList.concat(res.data.records)
        }
        this.total = res.data.total
        this.loading = false
        this.refreshing = false
        Toast.clear()

      })
    },
    //触底加载
    onLoad() {
      this.pageNum++
      this.loadHistoryOrders()
    },
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.orderHistoryList = []
      this.loadHistoryOrders()
    }
  }
}
</script>

<style scoped lang="less">
.orderHistory {
  padding: 10px 20px 1px;

  .order-history-item {
    padding: 10px 10px 0;
    margin-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .time {
        font-size: 13px;
        color: #999;
      }
    }

    .commodity {
      display: flex;
      margin-bottom: 10px;

      .commodity-img {
        width: 100px;
        height: 100px;
      }

      .commodity-content {
        flex: 1;
        margin: 0 20px;
        font-size: 12px;
        color: #999;

        .commodity-title {
          overflow: hidden;
          font-size: 15px;
          font-weight: bold;
          color: #222;
          margin-bottom: 10px;
        }
      }

      .prices {
        font-size: 13px;
        color: #999;
        text-align: right;

        .price {
          font-size: 15px;
          color: #222;
          margin-bottom: 5px;
        }
      }
    }

    .count-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #d8d8d8;

      .count {
        font-size: 13px;

        .money {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .btn {
        width: 90px;
        text-align: center;
        padding: 6px 0;
        border-radius: 16px;
        border: 1px #DE2727 solid;
        color: #DE2727;
      }
    }

    .order-no {
      padding: 14px 0;
      color: #999;
      display: flex;
      justify-content: space-between;

      .icon {
        font-size: 18px;
      }
    }
  }
}
</style>
