<template>
  <div class="UdeskCustomer">
    <van-nav-bar title="客户资料" />
    <div class="userInfo">
      <img v-if="userInfo.portrait" :src="userInfo.portrait" class="userInfo-img">
      <img v-else src="@/assets/udesk/customer/default.png" class="userInfo-img">
      <div class="userInfo-label-box userInfo-margin">
        <div class="userInfo-label">客户姓名</div>
        <div class="userInfo-label">手机号</div>
        <div class="userInfo-label">会员等级</div>
        <div class="userInfo-label">注册时间</div>
      </div>
      <div class="userInfo-label-box userInfo-text-box">
        <div class="userInfo-label">{{ userInfo.userName || '-' }}</div>
        <div class="userInfo-label">{{ userInfo.phone || '-' }}</div>
        <div class="userInfo-label">{{ userInfo.memberGrade || '-' }}</div>
        <div class="userInfo-label">{{ userInfo.registerDate || '-' }}</div>
      </div>
    </div>
    <van-tabs v-model="active">
      <van-tab v-for="(item) in tabList" :key="item.name" :title="item.label" :name="item.name">
        <orderHistory v-if="active === 'order' && userInfo.userId" :userId="userInfo.userId" :phone="phone">
        </orderHistory>
        <coupon v-if="active === 'coupon' && userInfo.userId" :userId="userInfo.userId"></coupon>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import orderHistory from './components/orderHistory'
import { Tab, Tabs, NavBar } from 'vant';
import { basicInfo } from "@/api/UdeskCustomer";
import Coupon from './components/coupon.vue';

export default {
  name: "UdeskCustomer",
  components: { Tab, Tabs, NavBar, orderHistory, Coupon },
  data() {
    return {
      tabList: [
        {
          label: '历史订单',
          name: 'order'
        },
        {
          label: '优惠券',
          name: 'coupon'
        }
      ],
      active: 'order',
      openId: '',
      phone: '',
      userInfo: {}
    }
  },
  mounted() {
    this.openId = this.$route.query.weixin_mini_openid || 'os8Xw4g-jfKWLvV5t0KZz5QB0DxE'
    this.phone = this.$route.query.agent_phone_number
    this.basicInfoFunc()
    // vant的下拉刷新和上拉加载：https://blog.csdn.net/qq_39111074/article/details/123187642
    // 没有对接接口，就先全注释掉了。
    // this.initArticleList()
  },
  methods: {
    basicInfoFunc() {
      basicInfo({ openId: this.openId }).then(res => {
        this.userInfo = res.data
      })
    },
  }
}
</script>

<style scoped lang="less">
.UdeskCustomer {
  width: 100%;
  min-height: 100vh;
  background-color: white;

  .userInfo {
    padding-top: 20px;
    padding-left: 20px;

    &-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      vertical-align: top;
    }

    &-label-box {
      display: inline-block;
      vertical-align: top;
    }

    &-text-box .userInfo-label {
      font-weight: bold;
    }

    &-label {
      font-size: 14px;
      font-weight: 400;
      height: 28px;
    }

    &-margin {
      margin: 0 40px 0 30px;
    }
  }
}
</style>
