import request from '@/lib/request'

//客服查看用户基本信息
export function basicInfo(data) {
  return request({
    url: '/h5/customer/basicInfo',
    method: 'get',
    params: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}

//客服查看用户历史订单
export function historyOrders(data) {
  return request({
    url: '/h5/customer/historyOrders',
    method: 'get',
    params: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}

//客服查看用户历史订单包裹信息
export function orderExpress(data) {
  return request({
    url: '/h5/customer/orderExpress',
    method: 'get',
    params: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}

//物流轨迹查询
export function getExpress(data) {
  return request({
    url: '/microService/mkk-general-server/expressage/track',
    method: 'post',
    data: data,
    baseURL: false,
    timeout: 60000 // 60秒
  })
}

//优惠券查询
export function getCoupon(data) {
  return request({
    url: '/h5/customer/coupon',
    method: 'get',
    params: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}

//备注查询
export function pageNote(data) {
  return request({
    url: '/h5/customer/pageNote',
    method: 'get',
    params: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}

//添加备注
export function saveNote(data) {
  return request({
    url: '/h5/customer/saveNote',
    method: 'post',
    data: data,
    signEncrypt: true,
    timeout: 60000 // 60秒
  })
}