<template>
	<div class="tab-swiper flex" :style="{'padding':padding}">
		<div class="li pr" :style="{'background-color':liBgColor,'border':liBorder,'border-radius':liBorderRadius,'color':(currentIndex == index ? acColor : '')}" :class="{'ac':currentIndex == index}" v-for="(item,index) in list" :key="index" @click="onChangeTab(index)">
			{{item.name}}
			<div class="pa num" v-if="item.num > 0">
				{{item.num > 100 ? '99+' : item.num}}
			</div>
		</div>
		<div style="min-width: 10rpx;"></div>
	</div>
</template>

<script>
	export default{
		name:'TabSwiper',
		props:{
			list:{
				type:Array,
				default:()=>[]
			},
			currentIndex:{
				type:Number,
				default:0
			},
			padding:{
				type:String,
				default:'20rpx 30rpx'
			},
			liBgColor:{
				type:String,
				default:''
			},
			liBorder:{
				type:String,
				default:''
			},
			liBorderRadius:{
				type:String,
				default:''
			},
			acColor:{
				type:String,
				default:'#DC2727'
			}
		},
		methods:{
			onChangeTab(index){
				this.$emit('changeTab',index)
			}
		}
	}
</script>

<style lang="less" scoped>
	.tab-swiper{
		overflow-x: auto;
		background-color: #fff;
    margin-bottom: 10px;
		&::-webkit-scrollbar{
		   height:2rpx;  
		}  
		.li{
			padding: 0 20px;
			height: 25px;
			border-radius: 12.5px;
			line-height: 25px;
			border: 1px solid #999999;
			font-size: 12px;
			color: #999999;
			min-width: 80px;
			box-sizing: border-box;
			text-align: center;
			margin-right: 10px;
			&.ac{
				border: 1px solid #DC2727;
				color: #DC2727;
			}
			.num{
				height: 12px;
				background: #ED3131;
				border-radius: 6px;
				font-size: 8px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 12px;
				padding: 0 5.5px;
				right: 0;
				top: -5.5px;
			}
		}
	}
</style>
