<template>
  <div class="">
    <div class="pro-list flex flex_align_center" v-for="(item, index) in list" :key="index">
      <img :src="item.goodsImage || ImgDefault" />
      <div class="flex_item" style="width: 50%;">
        <div class="flex flex_align_center flex_justify_between">
          <div class="flex_item title txtcut">
            {{ item.goodsTitle }}
          </div>
          <div class="num">
            x{{ item.buyNum }}
          </div>
        </div>
        <div class="class">
          {{ item.goodsAttr }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDefault from '@/assets/udesk/customer/default.png';
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      ImgDefault: ImgDefault
    }
  }
}
</script>

<style lang="less" scoped>
.pro-list {
  background-color: #fff;
  padding: 10px 15px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 2.5px;
    margin-right: 7px;
  }

  .title {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-right: 10px;
    max-width: 60%;
  }

  .num {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }

  .class {
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}
</style>
