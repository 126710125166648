<template>
	<div class="text-cell flex">
		<div class="title">
			{{title}}：
		</div>
		<div class="flex_item content">
			{{content}}
		</div>
		<slot></slot>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:''
			},
			content:{
				type:String,
				default:''
			}
		}
	}
</script>

<style lang="less" scoped>
	.text-cell{
		margin: 2.5px 0;
		.title{
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 18.5px;
			margin-right: 5px;
		}
		.content{
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 18.5rpx;
		}
	}
</style>
