import { render, staticRenderFns } from "./border.vue?vue&type=template&id=284b67b0&scoped=true&"
import script from "./border.vue?vue&type=script&lang=js&"
export * from "./border.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284b67b0",
  null
  
)

export default component.exports