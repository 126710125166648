<template>
  <div class="remarks">
    <div class="close">
      <img src="@/assets/udesk/customer/close.png" @click="close">
    </div>
    <div class="header">
      <div :class="[type == 1 ? 'red' : '']" @click="onToggle(1)">查看备注</div>
      <div :class="[type == 1 ? '' : 'red']" @click="onToggle(2)">新增备注</div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="list" v-if="type == 1">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" offset="10" @load="onLoad"
          :immediate-check=false>
          <div class="remark" v-for="(item, index) in dataList" :key="index">
            <div :class="['date', (index + 1) == dataList.length ? 'last' : '']">
              <div>{{ item.createTimeBefore }}</div>
              <div>{{ item.createTimeBehind }}</div>
            </div>
            <div class="msg">{{ item.content }}
              <div class="user-name">{{ item.userName }}</div>
            </div>
          </div>
          <NoData v-if="dataList && dataList.length == 0"></NoData>
        </van-list>
      </div>
      <div v-else class="flex">
        <div class="pr">
          <textarea class="textarea" placeholder="请填写备注" maxlength="1000" v-model="newRemark"></textarea>
          <div class="num">{{ numNow + '/' + numMax }}</div>
        </div>
        <div class="add" @click="onAdd">+确认新增</div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { pageNote, saveNote } from '@/api/UdeskCustomer'
import { Toast } from 'vant';
import NoData from '@/components/no-data/no-data.vue';

export default {
  name: 'remarks',
  components: {
    NoData
  },
  props: {
    orderId: {
      type: Number,
      default: null
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      type: 1,
      dataList: null,
      newRemark: '',
      numMax: 1000,
      pageNum: 1,
      total: null,
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    numNow() {
      return this.newRemark.length
    }
  },
  mounted() {
    this.loadPageNote()
  },
  methods: {
    loadPageNote() {
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      let pageNum = this.pageNum;
      const params = {
        pageNum,
        pageSize: 25,
        orderId: this.$props.orderId
      }
      if (this.dataList && this.dataList.length !== 0 && this.dataList.length == this.total) {
        this.finished = true
        this.refreshing = false
        Toast.clear()
        return
      }
      pageNote(params).then(res => {
        res.data.records.forEach(item => {
          item.createTimeBefore = item.createTime.slice(0, 10)
          item.createTimeBehind = item.createTime.slice(11)
        })
        if (this.pageNum == 1) {
          this.dataList = res.data.records
        } else {
          this.dataList = this.dataList.concat(res.data.records)
        }
        this.total = res.data.total
        this.loading = false
        this.refreshing = false
        Toast.clear()
      })
    },
    onToggle(type) {
      if (this.type == type) {
        return
      }
      this.type = type
      if (type == 1) {
        this.onRefresh()
      }
    },
    onAdd() {
      const params = {
        content: this.newRemark,
        orderId: this.$props.orderId,
        phone: this.$props.phone
      }
      saveNote(params).then(res => {
        if (res.data) {
          Toast.success('添加成功')
          this.newRemark = ''
        }
      })
    },
    close() {
      this.$emit('hideRemarks')
    },
    onLoad() {
      this.pageNum++
      this.loadPageNote()
    },
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.dataList = []
      this.loadPageNote()
    }
  }
}
</script>
<style>
</style>
<style lang="less" scoped>
.remarks {
  width: 100vw;
  height: 100vh;
  padding: 20px 25px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .close {
    text-align: right;

    img {
      width: 20px;
      height: 20px;
    }

  }

  .header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;

    .red {
      color: #DC2727;
      font-weight: 700;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 80%;
        height: 1.5px;
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #DC2727;
      }
    }
  }

  .list {
    max-height: 85vh;
    overflow: scroll;

    .remark {
      display: flex;
      margin-bottom: 10px;
      font-size: 13px;

      .date {
        flex-shrink: 0;
        padding-top: 10px;
        margin-right: 10px;
        box-sizing: border-box;
        position: relative;
        text-align: center;

        &.last {
          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          display: block;
          height: calc(100% - 30px);
          border: 0.5px dashed #333333;
          position: absolute;
          top: 42px;
          left: 50%;
          transform: translate(-50%, 0);
        }

      }

      .msg {
        width: 100%;
        padding: 10px;
        background-color: #F7F8F9;
        box-sizing: border-box;
        border-radius: 5px;
        word-break: break-all;

        .user-name {
          color: #999999;
          text-align: right;
          margin-top: 10px;
        }
      }
    }
  }

  .pr {
    width: 100%;
    position: relative;

    .textarea {
      width: 100%;
      height: 250px;
      resize: none;
      border-radius: 5px;
      padding: 10px 10px 30px 10px;
      box-sizing: border-box;
      background: #F7F8F9;
    }

    .num {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }


  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;

    .add {
      width: 60%;
      height: 50px;
      border-radius: 5px;
      color: white;
      background: linear-gradient(to top, #DE2727, #FF5252);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 200px;
      font-size: 16px;
    }
  }

}
</style>
