<template>
  <div class="express">
    <div class="close">
      <img src="@/assets/udesk/customer/close.png" @click="close">
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="init">
      <template v-if="dataList && dataList.length > 0">
        <TabSwiper :list="dataList" :currentIndex="currentIndex" @changeTab="changeTab">
        </TabSwiper>
        <Border :height="'10px'"></Border>
        <ProList :list="dataList[currentIndex].goods"></ProList>
        <Border :height="'10px'"></Border>
        <div class="box">
          <TextCell :title="'物流公司'" :content="dataList[currentIndex].expressCompany"></TextCell>
          <TextCell :title="'运单号码'" :content="dataList[currentIndex].expressNo">
            <template>
              <Copy class="copy" :copyInfo="dataList[currentIndex].expressNo"></Copy>
            </template>
          </TextCell>
        </div>
        <Border :height="'10px'"></Border>
        <div class="box" v-if="expressList && expressList.length > 0">
          <ExpressLine :list="expressList"></ExpressLine>
        </div>
        <NoData v-if="expressList && expressList.length == 0" :txt="'暂无物流信息~'"></NoData>
      </template>
      <NoData v-if="dataList && dataList.length == 0"></NoData>
    </van-pull-refresh>

  </div>
</template>

<script>
import { orderExpress, getExpress } from '@/api/UdeskCustomer'
import { Toast } from 'vant';
import TabSwiper from '@/components/tab-swiper/tab-swiper.vue';
import ProList from '@/components/pro-list/pro-list.vue';
import Border from '@/components/border/border.vue';
import TextCell from '@/components/text-cell/text-cell.vue';
import ExpressLine from '@/components/express-line/express-line.vue';
import Copy from '@/components/copy/copy.vue';
import NoData from '@/components/no-data/no-data.vue';
export default {
  components: {
    TabSwiper,
    ProList,
    Border,
    TextCell,
    ExpressLine,
    Copy,
    NoData
  },
  name: 'express',
  props: {
    orderId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dataList: null,
      currentIndex: 0,
      expressList: null,
      refreshing: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loadGoodsList().then(() => {
        this.changeTab(this.currentIndex);
      })
    },
    //加载包裹
    loadGoodsList() {
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      return new Promise((resolve, reject) => {
        orderExpress({
          orderId: this.$props.orderId
        }).then((res) => {
          let { data } = res;
          //处理data格式，列表加个name：“包裹” 
          data.forEach((item, index) => {
            item.name = '包裹' + (index + 1)
          })
          this.dataList = data;
          this.refreshing = false
          Toast.clear()
          resolve();
        })
      })
    },
    //加载物流信息
    loadExpress() {
      return new Promise((resolve, reject) => {
        getExpress({
          number: this.dataList[this.currentIndex].expressNo,
          companyCode: this.dataList[this.currentIndex].expressCompanyCode
        }).then(res => {
          this.expressList = res.data;
          this.refreshing = false
          resolve();
        })
      })
    },
    changeTab(data) {
      this.currentIndex = data;
      this.loadExpress()
    },
    close() {
      this.$emit('hideExpress')
    },

  }
}
</script>
<style lang="less" scoped>
.express {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 99;
  overflow: scroll;

  .close {
    text-align: right;

    img {
      width: 20px;
      height: 20px;
    }

  }

  .box {
    padding: 10px 15px;
    background-color: #fff;

    .copy {
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #DC2727;
      line-height: 17px;
    }
  }
}
</style>
